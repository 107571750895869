body {
  @apply font-sans bg-gray-100 text-gray-900;
  @apply dark:bg-gray-900 dark:text-gray-100;

  font-size: 18px;
}

a {
  @apply underline text-blue-700;
  @apply dark:text-blue-300;
  @apply hover:no-underline;
}

h1 {
  @apply text-3xl block mb-3;
}

h2 {
  @apply text-2xl block mb-3;
}

dt {
  @apply font-bold;
}

dd {
  @apply pl-4 mb-3;
}
