.footer {
  @apply py-6 px-4;

  a {
    @apply no-underline;
    @apply hover:underline;
  }

  h4 {
    @apply mb-2;
  }

  section {
    @apply mb-5;

    @screen md {
      @apply mb-0;
    }
  }
}
