.btn {
  @apply rounded transition-colors duration-200 py-2 px-3;
  @apply disabled:opacity-50;
}

.btn-primary {
  @apply bg-blue-700 text-white;
  @apply hover:bg-blue-800;
}

.center {
  @apply flex flex-col justify-center items-center;
}

.component-bg {
  @apply bg-gray-200;
  @apply dark:bg-gray-800;
}

.main-box {
  @apply w-full p-5 my-8 mx-0 rounded component-bg;

  @screen md {
    @apply mx-auto px-10;
  }

  @screen xl {
    @apply w-3/4;
  }

  &.main-box-small {
    @screen md {
      @apply w-3/4;
    }

    @screen lg {
      @apply w-1/2;
    }
  }
}

.form-group {
  @apply flex flex-col mb-4;
}

.form-label {
  @apply mb-2;
}

.form-input {
  @apply rounded bg-gray-100 text-gray-700 py-2 px-3;
  @apply dark:bg-gray-700 dark:text-gray-100;

  :not(:placeholder-shown):invalid {
    @apply border border-red-300;
    @apply dark:border-red-700;
  }
}
